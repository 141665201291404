import moment from "moment";
import plural from "plural-ru";
import { User } from "../entities";
import Cookie from "mobx-cookie";
import { getFirstnameGender } from "lvovich";
import { BonusCardModel } from "../components/bonusPage/bonusCard/model";
import cookies from "next-cookies";
import { GetServerSidePropsResult } from "next";
import { getForServerSide } from "./Axios";
import { REGISTRATION_TAG_PATHS } from "./dictionaries";
import SelectedCityStore from "../stores/SelectedCityStore";
import AuthStore from "../stores/AuthStore";

declare global {
  interface Window {
    dataLayer: any;
  }
}

export const isEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

export function addDays(theDate, days) {
  return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
}

export function get_city_label(city) {
  const citiesWithOnlyTitle = [93, 106, 90, 97];
  
  if (citiesWithOnlyTitle.includes(city?.region?.id)) {
    return city?.title || "";
  }
  
  return [city?.country?.title, city?.region?.title, city?.title]
    .filter((item) => !!item)
    .join(", ");
}

export function get_profile_city_label(city) {
  const citiesWithOnlyTitle = [93, 106, 90, 97];
  
  if (citiesWithOnlyTitle.includes(city?.region?.id)) {
    return city?.title || "";
  }

  return [city?.title, city?.region?.title]
    .filter((item) => !!item)
    .join(", ");
}

export function get_bs_address(data) {
  return [data.city?.title, data?.address].filter((item) => !!item).join(", ");
}

export function sliceNumbers(numbers) {
  if (numbers) {
    return numbers.toLocaleString("ru-RU");
  }
  return numbers;
}

export function getFaces(msk, not_msk, isMoscow) {
  if (msk) {
    if (isMoscow) {
      return not_msk;
    }
    return msk.concat(not_msk);
  }
  return [];
}

export function wordsByGender(
  first_name: string,
  male: string,
  female: string,
  def?: string
) {
  if (first_name) {
    const gender = getFirstnameGender(first_name);
    switch (gender) {
      case "male":
        return male;
      case "female":
        return female;
      default:
        return def || male || female;
    }
  } else {
    return def || male || female;
  }
}

export function getUserPhotoFromUser(user: User) {
  if (user?.photo?.profile) {
    return user.photo.profile;
  }
  if (user?.legacy_avatar) {
    return user.legacy_avatar;
  }
  return null;
}

export function range(start, stop, step) {
  return Array.from(
    { length: (stop - start) / step + 1 },
    (_, i) => start + i * step
  );
}

export function sendMetrics(eventText: string): void {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: eventText,
      eventCategory: "press",
      eventAction: "pressed",
    });
  }
}

export function getDaysLeft(date: string): string {
  // const duration = moment.duration(moment(new Date()).diff(moment(date)));
  // const days = Math.floor(Math.abs(duration.asDays()));
  const inputDate = new Date(date);
  const currentDate = new Date();

  // Устанавливаем время для обеих дат в полночь
  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Рассчитываем разницу в миллисекундах между датами
  const differenceInMs = currentDate.getTime() - inputDate.getTime();

  // Преобразуем разницу в дни, разделив на количество миллисекунд в одном дне (1000 миллисекунд * 60 секунд * 60 минут * 24 часа)
  const days = Math.abs(Math.ceil(differenceInMs / (1000 * 60 * 60 * 24)));
  const plur = plural(days, "день", "дня", "дней");
  const leftPlur = plural(days, "остался", "осталось", "осталось");
  if (days === 0) return `заканчивается сегодня`;
  if (days > 365) return `больше года`;
  return `${leftPlur} ${days} ${plur}`;
}

export const isPastDate =(date: string): boolean => {
  const inputDate = new Date(date);
  const currentDate = new Date();

  inputDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  return inputDate >= currentDate;
}

export const isShowBlocks = (
  isMobile,
  count,
  minimumDesktop = 4,
  minimumMobile = 2
): boolean => {
  if (isMobile) {
    return count >= minimumMobile;
  } else {
    return count >= minimumDesktop;
  }
};

export const morphNotTakenCityIdBonuses = (bonuses) => {
  return bonuses
    .filter((bonus) => {
      return bonus.is_taken === false;
    })
    .map((bonus) => new BonusCardModel(bonus));
};

export const getUserCityIdRoot = async (
  selectedCityStore: SelectedCityStore,
  authStore: AuthStore,
  citiesStore
) => {
  const city = selectedCityStore.city;
  const cityID = selectedCityStore.cityID;
  const cityData = selectedCityStore.cityData;
  const user = authStore.user;

  if (!city || !cityID) {
    if (user) {
      selectedCityStore.setCity(user.city_id === 1 ? "Moscow" : "Other");
      await selectedCityStore.setCityId(user.city_id);
    } else {
      const foundByCoords = await getUserCityByCoords(citiesStore);
      if (foundByCoords?.id) {
        selectedCityStore.setCity(foundByCoords.id === 1 ? "Moscow" : "Other");
        await selectedCityStore.setCityId(foundByCoords.id);
      }
    }
  } else {
    if (!cityData?.id) {
      selectedCityStore.setCity(city);
      await selectedCityStore.setCityId(cityID);
    }
  }
};

export const getUserCityId = async (
  authStoreUser,
  selectedCityStore,
  citiesStore
) => {
  const isUserAuthorized = !!authStoreUser;
  const CookieCity = selectedCityStore.city;
  const CookieCityID = selectedCityStore.cityID;
  const isUserCityFoundedByCoords = await getUserCityByCoords(citiesStore);

  if (!CookieCityID || !CookieCity) {
    if (isUserAuthorized) {
      selectedCityStore.setCityId(authStoreUser?.city_id);
      selectedCityStore.setCity(
        authStoreUser?.city_id === 1 ? "Moscow" : "Other"
      );
    }
    if (isUserCityFoundedByCoords?.id) {
      selectedCityStore.setCityId(isUserCityFoundedByCoords?.id);
      selectedCityStore.setCity(
        isUserCityFoundedByCoords?.id === 1 ? "Moscow" : "Other"
      );
    }
  } else {
    selectedCityStore.setCityId(CookieCityID);
    selectedCityStore.setCity(CookieCity);
  }
};

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export const getUserCityByCoords = async (
  citiesStore
): Promise<{
  lng: number | any;
  lat: number | any;
  id: number;
}> => {
  return getByPos()
    .then((response) => {
      const { latitude, longitude } = response.coords;
      return citiesStore
        .getCityByCoords(latitude, longitude)
        .then((response) => {
          return response;
        })
        .catch(() => {});
    })
    .catch(() => null);
};

export const getByPos = (): Promise<any> => {
  let cookie = new Cookie("DisableGeolocation");

  return new Promise((res, rej) => {
    navigator.geolocation.getCurrentPosition(res, (err) => {
      if (err && err.code === 1) {
        cookie.set("true", {
          expires: 365,
          httpOnly: false,
          domain: ".donorsearch.org",
        });
      }
      rej();
    });
  });
};

const checkToTrue = (variable: any) => {
  return (
    variable == "true" ||
    variable == "True" ||
    variable == "TRUE" ||
    variable == 1
  );
};

export const checkIfFeatureFlagEnabled = () => {
  const bonus = process.env.NEXT_PUBLIC_DS_DISABLE_BONUS;
  return checkToTrue(bonus);
};

export const getPreviousLocation = (
  utms = "?utm_source=header&utm_medium=ds&utm_campaign=donor_search&"
) => {
  if (typeof window !== "undefined") {
    if(location?.pathname?.startsWith('/vmesteokean/donors')) {
      return `/vmesteokean${utms}`
    }
    if (/^\/fundraising\/\d+\/?$/.test(location?.pathname) || location?.pathname?.startsWith('/donate')) {
      return `/login/${utms}next=${location.pathname}${location.search || ""}`
    } else {
      return `/login/${utms}next=${location.pathname}`;
    }
  }
  return `/login/${utms}/`;
};

export const removeFalsyPropValueFromObj = <T extends object>(
  obj: T
): object => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (
        obj[prop] === null ||
        obj[prop] === undefined ||
        (obj[prop] as unknown as keyof T) === 0
      ) {
        delete obj[prop];
      }
    }
  }
  return obj;
};

export const customGetServerSidePropsData = async (url, context) => {
  const token = cookies(context)?.token;
  const res = await getForServerSide(token)
    .get(url)
    .catch(() => {
      return { data: { notFound: true } };
    });
  if (res?.data?.notFound) {
    return {
      notFound: true,
    };
  }
  return res.data;
};

export const customGetServerSideProps = async (
  url,
  context,
  props = null
): Promise<GetServerSidePropsResult<any>> => {
  const token = cookies(context)?.token;
  const res = await getForServerSide(token)
    .get(url)
    .catch(() => {
      return { data: { notFound: true } };
    });
  if (res?.data?.notFound) {
    return {
      notFound: true,
    };
  }
  return {
    props: {
      initialData: res?.data,
      props,
    },
  };
};

export const customGetServerSidePropsWithoutRequest = async ({
  initialData = null,
  redirect = null,
  props = null,
}) => {
  if (redirect) {
    return {
      redirect,
      props: {
        initialData: initialData,
        props: props,
      },
    };
  } else {
    return {
      props: {
        initialData: initialData,
        props: props,
      },
    };
  }
};

export const getValueForRegistrationTags = (path: string) => {
  // console.log(document.referrer)
  if (document.referrer === "https://specials.donorsearch.org/") {
    return "AdviceAdvice";
  }
  if (document.referrer === "https://journal.donorsearch.org/") {
    return "Journal";
  }
  if (/^\/fundraising\/?$/.test(path)) {
    return "MainFundraising"
  } else if (/^\/fundraising\/\d+\/?$/.test(path)) {
    return "Fundraising"
  }
  if (path === "/") {
    return "MainPage";
  } else {
    return Object.keys(REGISTRATION_TAG_PATHS)
      .map((key) => {
        return path?.includes(key) ? REGISTRATION_TAG_PATHS[key] : false;
      })
      .filter(Boolean)[0];
  }
};

export const setDirectSignUpCookie = () => {
  if (
    window.history.length <= 1 ||
    !window.sessionStorage.prevPath ||
    window.sessionStorage.prevPath === "null"
  ) {
    const registrationTag = new Cookie("RegistrationTag");

    // if (!registrationTag.value) {
      // registrationTag.set('Direct_Sign_up', {expires: 365 })
      registrationTag.set("DirectSignUp", {
        expires: 365,
        domain: ".donorsearch.org",
      });
      registrationTag.set("DirectSignUp", {
        expires: 365,
        domain: "unity.redcross.ru",
      });
    // }
  }
};

export function formatDate(date) {

  let day = date.getDate();
  if (day < 10) day = '0' + day;

  let month = date.getMonth() + 1;
  if (month < 10) month = '0' + month;

  let year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

export const getOccasionObj = (occasion, occasionList) => {
  let currentObj = null;
  occasionList.forEach(obj => {
    if(obj.value === occasion) {
      currentObj = obj;
    }
  })

  return currentObj;
}

export const getCurrentMetaImg = (pathname: string) => {
  if(pathname?.includes('fundraising') || pathname?.startsWith('/donate')) {
    return 'fundraisingMetaImg.png'
  } else {
    return 'newMetaImg.png'
  }
}